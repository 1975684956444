import '../styles/order.sass'
import Popup from '../layout/Popup'
import { toast, Flip } from 'react-toastify'
import Pagination from '../components/Pagination'
import { AppContext } from '../context/AppContext'
import { cities, orderStatus } from '../context/Variables'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext, useCallback, useRef } from 'react'

const Order = () => {
    const popupTitle = useRef(null)
    const updatePopup = useRef(null)
    const { page = 1 } = useParams()
    const [ pages, setPages ] = useState(1)
    const [ orders, setOrders ] = useState(null)
    const [ post, setPost ] = useState({s: '', sort: 'o.date', inverse: 'desc', stock: ''})
    const { user, load, updateData, showTooltip, hideTooltip, apiURL } = useContext(AppContext)

    const updateField = useCallback((e) => {
        let _post = {...post}
        if(e.target.type == 'checkbox' && !e.target.checked)
            _post[e.target.name] = ''
        else
            _post[e.target.name] = e.target.value
        setPost(_post)
    }, [post])
    const showConfirm = (id, action, message) => {
        popupTitle.current.innerHTML = message
        updatePopup.current.setAttribute('el-id', id)
        updatePopup.current.setAttribute('el-action', action)
        updatePopup.current.classList.add('active')
    }
    const hideConfirm = () => updatePopup.current.classList.remove('active')
    const confirmUpdate = e => {
        update(updatePopup.current.getAttribute('el-id'), updatePopup.current.getAttribute('el-action'))
        hideConfirm()
    }
    const update = (id, action) => {
        let formData = new FormData()
        formData.append('id', id)
        if(action == 'cancel'){
            formData.append('status', 9)
            action = 'status'
        }
        formData.append('token', localStorage.getItem('userToken'))
        updateData('order', action, formData, data => {
            setPost({...post, updated: new Date()})
            toast.success(data.updated, { theme: "colored", transition: Flip })
        })
    }
    
    useEffect(() => {
        if(localStorage.getItem('userToken')){
            load(true)
            let formData = new FormData()
            for(let key in post)
                formData.append(key, post[key])
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}orders/${page > 1 ? `page/${page}/` : ''}`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        setOrders(data.data)
                        setPages(data.pages)
                    }
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
                .finally(() => load(false))
        }
        return () => hideTooltip()
    }, [page, post])

    return <section className="order">
        <Popup use={updatePopup}>
            <h2 className="text-center">Confirmación</h2>
            <p ref={popupTitle} className="text-center"></p>
            <hr />
            <div className="text-center">
                <a className="button gray" onClick={hideConfirm}>Cancelar</a>
                <a className="button" onClick={confirmUpdate}>Confirmar</a>
            </div>
        </Popup>
        <div className="content">
            <Link className="go-back" to="/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>Listado de pedidos</h1>
            <p>Consulte aquí los pedidos creados.</p>
            <div className="mobile">
                <Link className="button" to="/pedidos/crear/">Crear un nuevo pedido</Link>
            </div>
            <div className="filter">
                <div className="field search">
                    <span className="material-icons">search</span>
                    <input type="text" name="s" placeholder="Búsqueda rápida..." autoComplete="off" value={post?.s || ''} onChange={updateField} />
                </div>
                <div className="in-line">
                    <div className="field state">
                        <label htmlFor="status">Estado:</label>
                        <select name="status" value={post?.status || ''} onChange={updateField}>
                            <option value="">Todos</option>
                            <option value="1">Anulado</option>
                        </select>
                    </div>
                    <div className="field sort">
                        <label htmlFor="sort">Ordenar por:</label>
                        <select name="sort" value={post?.sort || ''} onChange={updateField}>
                            <option value="o.date">Fecha</option>
                            <option value="o.id">ID</option>
                            <option value="c.name">Cliente</option>
                            <option value="p.code">Sucursal</option>
                        </select>
                        <span className="material-icons">filter_alt</span>
                    </div>
                    <label htmlFor="inverse" className={`field sort-inverse ${post?.inverse}`}>
                        <span className="material-icons">arrow_drop_down</span>
                        <input type="checkbox" name="inverse" value="desc" checked={post?.inverse || ''} onChange={updateField} />
                    </label>
                </div>
            </div>
            {(orders?.length > 0 && <table className="list">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Cliente</th>
                        <th>Sucursal</th>
                        {user?.type < 2 && <th>Comercial</th> || null}
                        <th>Fecha Entrega</th>
                        <th>Estado</th>
                        <th className="right"><Link className="button green" to="/pedidos/crear/" onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Crear pedido"><span className="material-icons">add</span></Link></th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(o => <tr key={o.id}>
                        <td className="monospace">{o.id}</td>
                        <td>{o.name}</td>
                        <td><span onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip={(o?.city != 0 && cities.length > 0 && cities.find(c => c.id == o.city)?.name+', ' || '')+o?.address}>{o?.code}</span></td>
                        {user?.type < 2 && <td>{o.full_name}</td> || null}
                        <td>{o.date}</td>
                        <td>{orderStatus.find(s => s.id == o.status)?.name}</td>
                        <td className="right" width="136">
                            <Link className="button" to={`/pedidos/editar/${o.id}`} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Ver pedido"><span className="material-icons">{o.status == 0 ? 'edit' : 'visibility'}</span></Link>
                            {(user?.type < 2 && <>
                                {o.status == 0 && <a className="button" onClick={() => showConfirm(o.id, 'cancel', '¿Seguro que desea eliminar el pedido?')} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Anular pedido"><span className="material-icons">close</span></a> || null}
                            </>) || null}
                        </td>
                    </tr>)}
                </tbody>
            </table>) || <table className="list">
                <thead>
                    <tr>
                        <th><center>No hay pedidos que cumplan con los filtros de búsqueda.</center></th>
                    </tr>
                </thead>
            </table>}
            <Pagination pages={pages} url="pedidos" />
        </div>
    </section>
}
export default Order